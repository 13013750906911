exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-archive-lava-product-tsx": () => import("./../../../src/templates/archive-lava-product.tsx" /* webpackChunkName: "component---src-templates-archive-lava-product-tsx" */),
  "component---src-templates-page-template-coming-soon-tsx": () => import("./../../../src/templates/page-template-coming-soon.tsx" /* webpackChunkName: "component---src-templates-page-template-coming-soon-tsx" */),
  "component---src-templates-page-template-contacts-tsx": () => import("./../../../src/templates/page-template-contacts.tsx" /* webpackChunkName: "component---src-templates-page-template-contacts-tsx" */),
  "component---src-templates-page-template-cookies-policy-tsx": () => import("./../../../src/templates/page-template-cookies-policy.tsx" /* webpackChunkName: "component---src-templates-page-template-cookies-policy-tsx" */),
  "component---src-templates-page-template-home-page-tsx": () => import("./../../../src/templates/page-template-home-page.tsx" /* webpackChunkName: "component---src-templates-page-template-home-page-tsx" */),
  "component---src-templates-page-template-privacy-policy-tsx": () => import("./../../../src/templates/page-template-privacy-policy.tsx" /* webpackChunkName: "component---src-templates-page-template-privacy-policy-tsx" */),
  "component---src-templates-single-lava-product-tsx": () => import("./../../../src/templates/single-lava-product.tsx" /* webpackChunkName: "component---src-templates-single-lava-product-tsx" */)
}

