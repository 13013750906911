// NPM
import { graphql, useStaticQuery } from 'gatsby'

export const useOptionsData = (): Queries.optionsDataQuery => {
  const data = useStaticQuery(graphql`
    query optionsData {
      siteOptions: wpPage(id: {eq: "cG9zdDoy"}) {
        lavaSiteOptions {
          downloads {
            catalog {
              publicUrl
            }
          }
        }
      }
    }
  `)
  return data
}
